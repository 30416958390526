import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pretty',
    standalone: true,
})
export class PrettyPipe implements PipeTransform {
    transform(val: any) {
        return JSON.stringify(val, undefined, 8).replace(/ /g, '&nbsp;').replace(/\n/g, '<br/>');
    }
}
